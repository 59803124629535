<template>
    <div class="page">
        <el-form size="small" class="query-form searchForm" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="search(1)">
            <el-form-item prop="borrowCodeNumber" label="借阅单号：">
                <el-input v-model.trim="searchForm.borrowCodeNumber" maxlength="50" placeholder="请输入借阅单号"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="borrowPurpose" label="借阅目的：">
                <el-select v-model="searchForm.borrowPurpose" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('borrow_purpose')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="borrowTime" label="借阅时间：">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        v-model="searchForm.borrowTime"
                        type="date"
                        placeholder="请选择借阅时间" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="deadline" label="期限：">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        v-model="searchForm.deadline"
                        type="date"
                        placeholder="请选择期限" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="returnState" label="归还状态：">
                <el-select v-model="searchForm.returnState" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('return_state')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="keyWord" label="输入搜索：">
                <el-input v-model.trim="searchForm.keyWord" maxlength="50" placeholder="输入关键字"></el-input>
            </el-form-item>
            <el-form-item label-width="20px">
                <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
            <el-form-item label-width="0px" class="text_right">
                <el-button type="primary" icon="el-icon-plus" v-show="hasPermission('electronic:submitter:add')"
                           @click="addEdit(null, 'add')">借阅申请
                </el-button>
                <el-button type="primary" icon="el-icon-monitor" v-show="hasPermission('electronic:submitter:myAudit')"
                           @click="myAudit()">我的审核
                </el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-tabs v-model="searchForm.auditState" size="small" @tab-click="search(1)">
                <el-tab-pane :label="item.label" :name="item.value" v-for="(item, index) in tabsList" :key="index"></el-tab-pane>
            </el-tabs>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 402px)"
                    class="table" ref="multipleTable">
                <el-table-column prop="borrowCodeNumber" label="借阅单号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="borrowUserName" label="借阅人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="borrowPurpose" label="借阅目的" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("borrow_purpose", scope.row.borrowPurpose ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="borrowTime" label="借阅时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="deadline" label="期限" show-overflow-tooltip></el-table-column>
                <el-table-column prop="returnState" label="归还状态" show-overflow-tooltip v-if="searchForm.auditState != 0 || searchForm.auditState == ''">
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("return_state", scope.row.returnState ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="auditState" label="审核状态" show-overflow-tooltip v-if="searchForm.auditState != 0 || searchForm.auditState == ''">
                    <template slot-scope="scope">
                        <div v-if="scope.row.auditState != 0">
                            {{$dictUtils.getDictLabel("audit_state", scope.row.auditState,'')}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="200" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   v-show="hasPermission('electronic:submitter:view')"
                                   @click="addEdit(scope.row, 'view')">详情
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('electronic:submitter:edit') && scope.row.auditState == 0 ||  scope.row.auditState == 3"
                                   @click="addEdit(scope.row, 'edit')">修改
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('electronic:submitter:submit') && scope.row.auditState == 0 ||  scope.row.auditState == 3"
                                   @click="submitAudit(scope.row)">提交
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('electronic:auditor:auditLog')"
                                   @click="auditLog(scope.row.id)">审核记录
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('electronic:submitter:return') && scope.row.auditState == 2 && scope.row.returnState == 1"
                                   @click="returnData(scope.row.id)">归还
                        </el-button>
                        <el-button type="text" size="small" v-if="hasPermission('electronic:submitter:delete') && scope.row.auditState != 1 && scope.row.returnState != 1"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <div class="totalInfo">共 <span>{{total}}</span> 条记录，档案申请 <span>{{applyTotal}}</span> 次</div>
        </div>
        <SubmitterForm ref="submitterForm" @refreshDataList="search()"></SubmitterForm>
        <AuditLog ref="auditLog"></AuditLog>
    </div>
</template>

<script>
    import SubmitterForm from './submitterForm'
    import AuditLog from '../components/auditLog'

    export default {
        components: {SubmitterForm, AuditLog},
        data() {
            return {
                searchForm: {
                    keyWord: '',
                    auditState: '-1',
                    returnState: '',
                    deadline: '',
                    borrowTime: '',
                    borrowPurpose: '',
                    borrowCodeNumber: '',
                    current: 1,
                    size: 10,
                },
                tabsList: [],
                loading: false,
                dataList: [],
                total: 0,
                applyTotal: 0,
            }
        },
        mounted() {
            this.tabsList = this.$dictUtils.getDictList('audit_state')
            this.tabsList.unshift({
                value: '-1',
                label: '全部'
            })
            this.search()
        },
        methods: {
            // 查询
            search(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                if(p.auditState == '-1') {
                    p.auditState = ''
                }
                this.getTotal(p)
                this.$axios(this.api.record.borrowList, p, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    this.loading = false
                })
            },
            getTotal(p) {
                this.$axios(this.api.record.archivesBorrowTotalList, p, 'post').then((res) => {
                    if (res.data && res.status) {
                        this.applyTotal = res.data
                    }
                })
            },
            // 新增
            addEdit(row, method) {
                this.$refs.submitterForm.init(row, method)
            },
            // 我的审核
            myAudit() {
                this.$router.push({name: 'electronicAuditorList'})
            },
            // 提交审核
            submitAudit(row) {
                this.$confirm(`确定提交审核吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.electronicSubmit + '/' + row.id, {}, 'put').then(data => {
                        if (data.status) {
                            this.$message.success('提交成功')
                            this.search()
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 审核记录
            auditLog(id) {
                this.$refs.auditLog.init(id, 0)
            },
            // 归还
            returnData(id) {
                this.$confirm(`确定归还吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.electronicReturn + '/' + id, {}, 'put').then(data => {
                        if (data.status) {
                            this.$message.success('归还成功')
                            this.search()
                        } else {
                            this.$message.success('归还失败')
                        }
                    })
                })
            },
            // 删除
            del(id) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.borrowRemoveById + '/' + id, {}, 'delete').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                            this.search()
                        } else {
                            this.$message.error('删除失败')
                        }
                    })
                })
            },
            // 重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.searchForm.auditState = '-1'
                this.search(1)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped lang="scss">
    .searchForm {
        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 25%;
        }
    }
</style>
